import React from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckedBox from 'components/Icon/CheckedBox';
import UncheckedBox from 'components/Icon/UncheckedBox';

interface Props extends CheckboxProps {
  fillColor?: string;
}
const Checkbox: React.FC<Props> = ({ fillColor, ...props }) => (
  <MuiCheckbox
    icon={<UncheckedBox disabled={props.disabled} fillColor={fillColor} />}
    checkedIcon={<CheckedBox />}
    {...props}
  />
);

export default Checkbox;
