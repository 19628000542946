import { ApolloError, useQuery } from '@apollo/client';
import { CampaignInterface } from 'pages/Admin/Campaigns/campaigns.context';
import { GET_CAMPAIGN } from 'graphql/campaigns/queries/getCampaign.gql';

export const useGetCampaignByIdQuery = (
  id?: string | number,
  skip?: boolean
): {
  campaign: CampaignInterface;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { data, error, loading, refetch } = useQuery(GET_CAMPAIGN, {
    skip: !id || skip,
    variables: { id: Number(id) },
  });

  return { campaign: data?.campaign, loading, error, refetch };
};
