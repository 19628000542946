import React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Drawer } from '@mui/material';
import { useClientContext } from 'context/client.context';
import SidenavDrawer from 'layout/Sidenav/SidenavDrawer';
import { SideNavGroupType } from 'layout/Layout';
import { SidebarDrawerProps } from './Sidenav.types';

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<SidebarDrawerProps>(({ theme, open, $drawerWidth }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    width: `${$drawerWidth}rem`,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    border: 'none',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
      opacity: 0,
    }),
    a: {
      width: '77%',
      marginLeft: '1.25rem',
      borderRadius: '0.25rem',
    },
  },
}));

const Sidenav = ({ sideNavGroup }: { sideNavGroup?: SideNavGroupType }) => {
  const { drawerWidth, md, showSidebar, toggleShowSidebar } = useClientContext();

  return (
    <>
      {md ? (
        <DesktopDrawer
          variant='permanent'
          open={!!sideNavGroup}
          $drawerWidth={drawerWidth}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <SidenavDrawer sideNavGroup={sideNavGroup} />
        </DesktopDrawer>
      ) : (
        <Drawer
          container={document.body}
          variant='temporary'
          open={showSidebar}
          onClose={toggleShowSidebar}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: `${drawerWidth}rem` },
          }}
        >
          <SidenavDrawer sideNavGroup={sideNavGroup} />
        </Drawer>
      )}
    </>
  );
};

export default Sidenav;
