import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AlertColor } from '@mui/material/Alert';

export const DEFAULT_AUTOHIDE_DURATION = 3000;

export interface ApiResult {
  success: boolean;
  error?: string | null;
}

interface SnackbarContextInterface {
  showSnackbar: boolean;
  setShowSnackbar: (showSnackbar: boolean) => void;
  alertSeverity?: AlertColor | 'critical';
  alertMessage: string;
  //duration to show the snackbar in milliseconds
  autoHideDuration: number;
  displaySnackbarMessage: (
    message: string,
    type?: AlertColor | 'critical',
    autoHideDuration?: number
  ) => void;
  displayApiResult: (apiResult: ApiResult, successMessage?: string) => void;
}

const defaultContext: SnackbarContextInterface = {
  showSnackbar: false,
  setShowSnackbar: () => {},
  alertMessage: '',
  autoHideDuration: DEFAULT_AUTOHIDE_DURATION,
  displaySnackbarMessage: () => {},
  displayApiResult: () => {},
};

const SnackbarContext = createContext<SnackbarContextInterface>(defaultContext);

export const SnackbarContextProvider = ({ children }: { children: ReactNode }) => {
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | 'critical' | undefined>();
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [autoHideDuration, setAutoHideDuration] = useState<number>(DEFAULT_AUTOHIDE_DURATION);

  const displaySnackbarMessage = (
    message: string,
    type?: AlertColor | 'critical',
    autoHideDuration?: number
  ) => {
    setAlertSeverity(type);
    setAlertMessage(message);
    setShowSnackbar(true);
    setAutoHideDuration(autoHideDuration ?? DEFAULT_AUTOHIDE_DURATION);
  };

  const displayApiResult = (apiResult: ApiResult, successMessage?: string) => {
    if (!apiResult.success) {
      displaySnackbarMessage(apiResult.error || 'Encountered unexpected error', 'error');
    } else if (successMessage) {
      displaySnackbarMessage(successMessage, 'success');
    }
  };

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
        setShowSnackbar,
        alertSeverity,
        alertMessage,
        autoHideDuration,
        displaySnackbarMessage,
        displayApiResult,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);
