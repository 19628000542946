import { generatePath } from 'react-router-dom';
import { ActivityAssignmentData } from 'hooks/activities/useQueryActivityAssignment';
import { Activity } from '__generated__/graphql';

export function withSearchParams(url: string, searchParams: URLSearchParams) {
  const queryString = searchParams.toString();
  if (queryString.length === 0) {
    return url;
  }

  return `${url}?${queryString}`;
}

export enum AppPath {
  ProjectReportsList = '/projects/:projectId/reports/individual',
  ProjectReportsListCombined = '/projects/:projectId/reports/combined',
  ProjectReportsListLog = '/projects/:projectId/reports/log',
  TemplateResponseView = '/projects/:projectId/reports/responses/:templateResponseId',
  TemplateResponseCombined = '/projects/:projectId/templates/:stepTemplateId/responses',
  ActivityList = '/projects/:projectId/activities',
  ActivityCreate = '/projects/:projectId/activities/create',
  ActivityCopy = '/projects/:projectId/activities/copy/:activityId',
  ActivityView = '/projects/:projectId/activities/:activityId',
  ActivityEdit = '/projects/:projectId/activities/edit/:activityId',
  ActivityAssignmentView = '/projects/:projectId/activities/:activityId/assignments/:assignmentId',
  NotAuthorized = '/unauthorized',
}

export const appChildPaths: {
  [AppPath.TemplateResponseCombined]: {
    List: string;
    StepResponsesView: string;
    StepGroupView: string;
  };
} = {
  [AppPath.TemplateResponseCombined]: {
    List: 'list',
    StepGroupView: 'groups/:stepGroupName/:questionNumber',
    StepResponsesView: 'groups/:stepGroupName/:questionNumber/steps/:stepId',
  },
};

export function makeChildPath<T extends keyof typeof appChildPaths>(
  path: T,
  childPath: keyof (typeof appChildPaths)[T]
) {
  return `${path}/${appChildPaths[path][childPath]}`;
}

export function getChildUrl<T extends keyof typeof appChildPaths>(
  path: T,
  childPath: keyof (typeof appChildPaths)[T],
  params?: Record<string, string | number | null>
) {
  return getUrlWithTokens(makeChildPath(path, childPath), params ?? {});
}

function getUrlWithTokens(path: string, params: Record<string, string | number | null>) {
  return generatePath(path, params);
}

type UrlParam = string | number;
type WithProjectId = { projectId: UrlParam };
type WithActivityId = WithProjectId & { activityId: UrlParam };

export function getProjectReportsListUrl(params: WithProjectId) {
  return getUrlWithTokens(AppPath.ProjectReportsList, params);
}

export function getProjectReportsListCombinedUrl(params: WithProjectId) {
  return getUrlWithTokens(AppPath.ProjectReportsListCombined, params);
}

export function getProjectReportsListLogUrl(params: WithProjectId) {
  return getUrlWithTokens(AppPath.ProjectReportsListLog, params);
}

export function getTemplateResponseViewUrl(
  params: WithProjectId & { templateResponseId: UrlParam }
) {
  return getUrlWithTokens(AppPath.TemplateResponseView, params);
}

export function getTemplateResponseCombinedUrl(
  params: WithProjectId & { stepTemplateId: UrlParam }
) {
  return getUrlWithTokens(AppPath.TemplateResponseCombined, params);
}

export function getCombinedStepResponsesUrl(
  params: WithProjectId & {
    stepTemplateId: UrlParam;
    stepGroupName: string;
    stepId: UrlParam;
  }
) {
  return getChildUrl(AppPath.TemplateResponseCombined, 'StepResponsesView', params);
}

export function getCombinedStepGroupsUrl(
  params: WithProjectId & {
    stepTemplateId: UrlParam;
    stepGroupName?: string;
    questionNumber?: string;
  }
) {
  return getChildUrl(AppPath.TemplateResponseCombined, 'StepGroupView', params);
}

export function getActivityListUrl(params: WithProjectId) {
  return getUrlWithTokens(AppPath.ActivityList, params);
}

export function getActivityCreateUrl(params: WithProjectId) {
  return getUrlWithTokens(AppPath.ActivityCreate, params);
}

export function getActivityCopyUrl(params: WithActivityId) {
  return getUrlWithTokens(AppPath.ActivityCopy, params);
}

export function getActivityViewUrl(params: WithActivityId) {
  return getUrlWithTokens(AppPath.ActivityView, params);
}

export function getActivityEditUrl(params: WithActivityId) {
  return getUrlWithTokens(AppPath.ActivityEdit, params);
}

export function getActivityAssignmentViewUrl(params: WithActivityId & { assignmentId: UrlParam }) {
  return getUrlWithTokens(AppPath.ActivityAssignmentView, params);
}

export function buildActivityViewUrl(activity: Pick<Activity, 'id'>, campaignId: UrlParam) {
  return getActivityViewUrl({
    projectId: Number(campaignId),
    activityId: activity.id,
  });
}

export function buildActivityAssignmentViewUrl(
  assignment: Pick<ActivityAssignmentData, 'activity' | 'id'>,
  campaignId: UrlParam,
  activityId?: UrlParam
) {
  return getActivityAssignmentViewUrl({
    projectId: Number(campaignId),
    activityId: activityId ?? assignment.activity.id,
    assignmentId: assignment.id,
  });
}
