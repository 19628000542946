import { ApolloError, useQuery } from '@apollo/client';
import { SortByInterface, Tenant, TenantFilters } from '__generated__/graphql';
import { GET_TENANTS } from 'graphql/tenants/queries/getTenants.gql';

export const useGetTenantsQuery = ({
  filters,
  sortBy = { sortByColumn: 'name' },
}: {
  filters?: TenantFilters;
  sortBy?: SortByInterface;
}): {
  tenants: Tenant[];
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { data, error, loading, refetch } = useQuery(GET_TENANTS, {
    variables: {
      tenantsInput: {
        filters,
        sortBy,
      },
    },
  });

  return { tenants: data?.tenants ?? [], loading, error, refetch };
};
