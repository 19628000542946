import React from 'react';
import { ElementProps, StyledElement } from 'components/Elements/elements.types';

export const Div = ({
  children,
  flexDirection,
  flexGrow,
  flexWrap,
  alignItems,
  textAlign,
  justifyContent,
  display,
  width,
  padding,
  margin,
  height,
  bgColor,
  color,
  borderRadius,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  position,
  top,
  right,
  bottom,
  left,
  fontWeight,
  onClick,
  gap,
}: ElementProps) => (
  <StyledElement
    $display={display}
    $alignItems={alignItems}
    $textAlign={textAlign}
    $justifyContent={justifyContent}
    $flexDirection={flexDirection}
    $flexWrap={flexWrap}
    $flexGrow={flexGrow}
    $width={width}
    $height={height}
    $padding={padding}
    $margin={margin}
    $bgColor={bgColor}
    $color={color}
    $borderRadius={borderRadius}
    $maxWidth={maxWidth}
    $maxHeight={maxHeight}
    $minWidth={minWidth}
    $minHeight={minHeight}
    $position={position}
    $top={top}
    $right={right}
    $bottom={bottom}
    $left={left}
    className='divy'
    onClick={onClick}
    $fontWeight={fontWeight}
    $gap={gap}
  >
    {children}
  </StyledElement>
);
