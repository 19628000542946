import React from 'react';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { css, styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import { SvgIconProps } from '@mui/material/SvgIcon';

export const severityIcons: Partial<
  Record<AlertColor | 'critical', React.ComponentType<SvgIconProps>>
> = {
  warning: WarningIcon,
  error: WarningAmberIcon,
  info: InfoIcon,
  success: CheckIcon,
  critical: WarningAmberIcon,
};

const Alert = styled(MuiAlert)`
  border-width: 0.0125rem 0.0125rem 0.1rem;
  border-style: solid;
  border-radius: 0.25rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 0.5625rem;

  .MuiAlert-icon {
    margin-top: 0.125rem;
  }

  ${({
    severity,
    theme: {
      colors: { alertColors },
    },
  }) =>
    severity &&
    alertColors[severity] &&
    css`
      background: ${alertColors[severity].backgroundColor};
      border-color: ${alertColors[severity].borderColor};
      color: ${alertColors[severity].textColor};

      .MuiAlert-icon {
        color: ${alertColors[severity].iconColor};
      }
    `}
`;

type Props = AlertProps;

const AlertMessage = ({ children, ...props }: Props) => {
  const IconComponent = props.severity && severityIcons[props.severity];
  return (
    <Alert icon={IconComponent && <IconComponent color='inherit' fontSize='inherit' />} {...props}>
      {children}
    </Alert>
  );
};

export default AlertMessage;
