import { v4 as uuidv4 } from 'uuid';

export const getUniqueKey = (data: Object): string => JSON.stringify(data);

export function generateUniqueUUIDs(count: number): string[] {
  const uuids: string[] = [];
  while (uuids.length < count) {
    const uuid = uuidv4();
    if (!uuids.includes(uuid)) {
      uuids.push(uuid);
    }
  }
  return uuids;
}
