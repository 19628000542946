import React from 'react';
import styled from 'styled-components';
import dateSelect from 'assets/icons/dateSelect.svg';
import dateSelectHover from 'assets/icons/dateSelectHover.svg';

const Img = styled.div`
  background: url(${dateSelect}) no-repeat center;
  height: 1.71375rem;
  width: 1.71375rem;
  &:hover,
  &:focus {
    background-image: url(${dateSelectHover});
    transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: pointer;
  }
`;

type Props = {
  onClick?: () => void;
  className?: string;
};

const DeleteIcon: React.FC<Props> = ({ className, onClick, ...props }) => {
  return (
    <Img {...props} className={className} onClick={onClick} aria-labelledby='date-icon-title'>
      <title id='date-icon-title'>Date Select Icon</title>
    </Img>
  );
};

export default DeleteIcon;
