import React, { useCallback, useState } from 'react';
import Modal from '../Modal/Modal';
import { Backdrop, DialogContent, Typography } from '@mui/material';
import Form from '../Form/Form';
import { useAuthContext } from '../../context/auth.context';
import { useLazyQuery } from '@apollo/client';
import { COGNITO_LOGIN } from 'graphql/auth/queries/authLogin';
import { FormFieldInterface } from '../Form/Form.types';
import { LoginChallengeResponse } from '__generated__/graphql';
import LoginChallenge from 'pages/Auth/Login/components/LoginChallenge/LoginChallenge';
import { themeOptions } from '../../theme';
import { useTheme } from '@mui/material/styles';
import ForgotPasswordModal from './ForgotPasswordModal';

type TenancyModalProps = {
  tenantId: number;
  tenantName: string;
  open: boolean;
  onClose: () => void;
};

const TenancyModal = ({ tenantId, tenantName, open, onClose }: TenancyModalProps) => {
  const theme = useTheme();
  const { login, logout, user } = useAuthContext();
  const [password, setPassword] = useState<string>('');
  const [challenge, setChallenge] = useState<LoginChallengeResponse | undefined>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [cognitoLogin] = useLazyQuery(COGNITO_LOGIN);

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState<boolean>(false);
  const handleCloseForgotPassword = useCallback(() => setForgotPasswordOpen(false), []);
  const handleOpenForgotPassword = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setForgotPasswordOpen(true);
  }, []);

  const fields: FormFieldInterface[] = [
    {
      label: 'Password',
      onChange: setPassword,
      type: 'password',
      value: password,
      containedLabel: true,
    },
  ];

  const handleSubmit = useCallback(() => {
    cognitoLogin({
      variables: {
        input: {
          tenantId,
          userEmail: user?.user.email,
          userPassword: password,
          destination: '/projects',
        },
      },
    })
      .then((result) => {
        if (result?.data?.cognitoLogin) {
          if (result.data.cognitoLogin.data) {
            logout(false);
            login(result.data.cognitoLogin.data, undefined, false, true);
            onClose();
          } else {
            setChallenge(result?.data?.cognitoLogin?.challenge);
          }
        } else {
          setErrorMessage('The email or password is incorrect');
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, [cognitoLogin, login, onClose, password, tenantId, user?.user.email]);

  return (
    <Backdrop open={open} sx={{ backgroundColor: theme.colors.black60 }}>
      <Modal
        open={open}
        onClose={onClose}
        title='Verify account'
        disableDivider
        dialogProps={{ hideBackdrop: true, maxWidth: 'xs' }}
        dialogTitleProps={{ sx: { fontSize: '2rem' } }}
      >
        <DialogContent sx={{ pl: 4, pr: 4 }}>
          <Typography fontSize={18}>Enter your password to log in to {tenantName}</Typography>
          {challenge && tenantId ? (
            <LoginChallenge
              tenantId={tenantId}
              email={user?.user.email ?? ''}
              challenge={challenge}
              onError={setErrorMessage}
              onNewChallenge={setChallenge}
              onComplete={onClose}
            />
          ) : (
            <Form
              fields={fields}
              buttonText='Log in'
              onSubmit={handleSubmit}
              submitButtonProps={{
                fullWidth: true,
                color: 'primary',
                variant: 'contained',
                sx: {
                  '&:disabled': {
                    backgroundColor: theme.colors.brandBlue30,
                    borderColor: theme.colors.neutral300,
                    color: theme.colors.white,
                  },
                },
              }}
              disabled={!password}
              sx={{ p: 0 }}
            />
          )}
          {errorMessage && (
            <Typography color={themeOptions.colors.error900} mt={2}>
              * {errorMessage}
            </Typography>
          )}
          <Typography variant='body2' mt={4} mb={2} align='center'>
            <a
              onClick={handleOpenForgotPassword}
              href={`/forgotPassword/${tenantId}/${encodeURIComponent(user?.user.email ?? '')}`}
            >
              Forgot your password?
            </a>{' '}
          </Typography>
        </DialogContent>
      </Modal>
      <ForgotPasswordModal
        tenantId={tenantId}
        tenantName={tenantName}
        email={user?.user.email ?? ''}
        open={forgotPasswordOpen}
        onClose={handleCloseForgotPassword}
      />
    </Backdrop>
  );
};

export default TenancyModal;
