import React, { useState } from 'react';
import { DropdownOption } from 'components/DropdownSelect/dropdown.types';
import SingleSelectDropdown from './SingleSelectDropdown';
import { MultiselectDropdownProps } from 'components/DropdownSelect/MultiSelectDropdown/MultiselectDropdown';
import { getTimezones } from 'utils/dates';

interface TimezoneSingleSelectInterface {
  onChange: (selectedTimezone: string) => void;
  label?: string;
  containedLabel?: boolean;
  inputProps?: Omit<MultiselectDropdownProps['inputProps'], 'sx'>;
  selectedTimezone?: string;
  required?: boolean;
  showValues?: boolean;
  marginTop?: string;
}

// This component is outside of the Usage folder on purpose; there will be an effort to move all of these outside of the Usage folder and remove the Dropdown from their names
const TimezoneSingleSelect = ({
  onChange,
  label,
  containedLabel,
  inputProps,
  selectedTimezone,
  required = true,
  showValues = false,
  marginTop,
}: TimezoneSingleSelectInterface) => {
  const timezones = getTimezones();
  const timezoneOptions: DropdownOption[] = timezones.map((timezone, index) => ({
    name: showValues ? timezone.value : timezone.name,
    value: timezone.value,
    id: index,
  }));
  const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(
    timezoneOptions.find((timezone) => timezone.value === selectedTimezone)
  );

  const handleChange = (newSelectedOption?: DropdownOption) => {
    setSelectedOption(newSelectedOption);
    onChange(newSelectedOption?.value ?? '');
  };

  return (
    <SingleSelectDropdown
      fieldLabel={label ?? 'Time Zone'}
      allOptions={timezoneOptions}
      fullWidth={true}
      selected={selectedOption}
      setSelected={handleChange}
      variant='outlined'
      containedLabel={containedLabel}
      inputProps={inputProps}
      required={required}
      disableClearable={required}
      marginTop={marginTop}
    />
  );
};

export default TimezoneSingleSelect;
