import React, { useMemo } from 'react';
import { Outlet, useLocation, Navigate, matchRoutes } from 'react-router-dom';
import { useAuthContext } from 'context/auth.context';
import { privateRoutes } from './routes';
import Loading from '../components/Messages/Loading/Loading';
import NotAuthorized from 'pages/NotAuthorized';

function PrivateRoute() {
  const { loading, isSessionActive, hasPermission } = useAuthContext();
  const location = useLocation();
  const matchedRoute = matchRoutes(privateRoutes, location);

  const permissible = useMemo(() => {
    if (!matchedRoute) {
      return <Outlet />;
    }

    if (matchedRoute[0].route.isAdmin && !hasPermission('access', 'Admin')) {
      return false;
    }

    const permission = matchedRoute[0].route.permission;
    return (
      !permission || hasPermission(permission.action, permission.subject, permission?.conditions)
    );
  }, [location.pathname, hasPermission]);

  if (loading) {
    return <Loading />;
  } else if (!isSessionActive()) {
    return <Navigate to={'/login'} />;
  } else if (!permissible) {
    return <NotAuthorized />;
  }

  return <Outlet />;
}

export default PrivateRoute;
