import React, { FormEvent, useState } from 'react';
import { Typography, Button, Grid, CircularProgress } from '@mui/material';
import { FormContainer } from 'components/FormContainer/FormContainer.styled';
import { FormFieldInterface, FormInterface } from 'components/Form/Form.types';
import FormField from 'components/Form/FormField';
import ErrorText from '../ErrorText/ErrorText';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { ErrorTextWrapper } from '../StyledComponents';

const Form = ({
  title,
  titleTestAttribute,
  description,
  descriptionTestAttribute,
  fields,
  submitButton,
  submitButtonProps,
  onSubmit,
  submitting,
  disabled,
  buttonText,
  index,
  cancelButton,
  cancelButtonProps,
  cancelButtonText,
  handleCancel,
  sx,
  compact,
  textAlign,
  showValidationMessage,
  isFormInvalid: externalIsFormInvalid,
  children,
  buttonMargin,
  overrideSubmitFunctionality,
}: FormInterface): JSX.Element => {
  const [fieldValues, setFieldValues] = useState(
    fields.map((field, i) => ({
      key: i,
      valid: field.requiredMinimumLength
        ? (String(field.value ?? '').length ?? 0) >= field.requiredMinimumLength
        : true,
    }))
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit && onSubmit();
  };

  //If we're externally
  const isFormInvalid =
    !!fieldValues.find((fieldValue) => !fieldValue.valid) || externalIsFormInvalid;
  const isFormDisabled = disabled || isFormInvalid;

  const handleChange = (value: string, field: FormFieldInterface, key?: number) => {
    if (!field.validator || field.validator(value)) {
      field.onChange && field.onChange(value, index ?? null);
    }

    if (field.requiredMinimumLength) {
      const fieldValuesCopy = [...fieldValues];
      const valid = value.length >= field.requiredMinimumLength;

      fieldValuesCopy[key!] = { ...fieldValues[key!], valid };
      setFieldValues(fieldValuesCopy);
    }
  };

  return (
    <FormContainer sx={sx} $textAlign={textAlign}>
      {title && (
        <div className='titleContainer'>
          <Typography variant='h5' paddingBottom={1} {...titleTestAttribute}>
            {title}
          </Typography>
        </div>
      )}
      {description && (
        <div className='descriptionContainer'>
          {typeof description === 'string' ? (
            <Typography {...descriptionTestAttribute}>{description}</Typography>
          ) : (
            description
          )}
        </div>
      )}
      <form onSubmit={(e) => onSubmit && !overrideSubmitFunctionality && handleSubmit(e)}>
        {children}
        <Grid container paddingBottom={2} spacing={1} mt={1}>
          {fields.map(
            (field, i) =>
              !field.hide && (
                <Grid
                  xs={field.xs ?? 12}
                  sm={field.sm}
                  md={field.md}
                  lg={field.lg}
                  xl={field.lg}
                  item
                  key={`${field.label}-${field.type}-${i}`}
                >
                  <FormField
                    field={field}
                    fieldIndex={i}
                    onChange={handleChange}
                    marginTop={compact ? '0rem' : '1rem'}
                  />
                </Grid>
              )
          )}
        </Grid>
        {isFormInvalid && showValidationMessage && (
          <ErrorTextWrapper style={{ textAlign: 'left', marginLeft: 0, marginTop: '1rem' }}>
            <ErrorText>
              <ErrorIcon />
              Please ensure all required fields are filled in correctly before proceeding
            </ErrorText>
          </ErrorTextWrapper>
        )}
        {onSubmit && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              margin: buttonMargin,
            }}
          >
            {cancelButton && (
              <Button
                onClick={handleCancel}
                style={{ marginRight: '1rem' }}
                variant='contained'
                color='secondary'
                {...cancelButtonProps}
              >
                {cancelButtonText ?? 'Cancel'}
              </Button>
            )}
            {submitButton ?? (
              <Button
                type={overrideSubmitFunctionality ? 'button' : 'submit'}
                variant='contained'
                disabled={isFormDisabled || submitting}
                onClick={() => {
                  if (overrideSubmitFunctionality) {
                    onSubmit();
                  }
                }}
                {...submitButtonProps}
              >
                {submitting ? (
                  <CircularProgress size='1.75rem' color='inherit' />
                ) : (
                  buttonText ?? 'Submit'
                )}
              </Button>
            )}
          </div>
        )}
      </form>
    </FormContainer>
  );
};

export default Form;
