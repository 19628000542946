import { useAuthContext } from 'context/auth.context';
import { privateRoutes, RouteInterface } from 'router/routes';
export interface LayoutLinkInterface {
  path: string;
  shortcutPath?: string;
  title: string;
  selected?: boolean;
  external?: boolean;
  testId?: string;
}

const rawAdminMainLinks: LayoutLinkInterface[] = [
  {
    path: '/admin/tenants',
    title: 'Tenants',
    testId: 'sidebar-tenants',
  },
  {
    path: '/admin/projects',
    title: 'Projects',
    testId: 'sidebar-projects',
  },
  {
    path: '/admin/stepTemplates',
    title: 'Templates',
    testId: 'sidebar-templates',
  },
  {
    path: '/admin/media-categories',
    title: 'Media Categories',
    testId: 'sidebar-media-categories',
  },
  {
    path: '/admin/stores',
    title: 'Stores',
    testId: 'sidebar-stores',
  },
  {
    path: '/admin/brands',
    title: 'Brands',
    testId: 'sidebar-brands',
  },
  {
    path: '/admin/users',
    title: 'Users',
    testId: 'sidebar-users',
  },
  {
    path: '/admin/groups',
    title: 'Permissions',
    testId: 'sidebar-permissions',
  },
  {
    path: '/admin/pillars',
    title: 'Pillars',
    testId: 'sidebar-pillars',
  },
  {
    path: '/admin/tag-groups',
    title: 'Tags',
    testId: 'sidebar-tags',
  },
  {
    path: '/admin/bulletins',
    title: 'Bulletins',
    testId: 'sidebar-bulletins',
  },
];

export const rawUserMainLinks: LayoutLinkInterface[] = [
  {
    path: '/dashboard',
    shortcutPath: '/projects/dashboard',
    title: 'Dashboard',
  },
  {
    path: '/reports',
    shortcutPath: '/projects/reports',
    title: 'Reports',
  },
  {
    path: '/activities',
    shortcutPath: '/projects/activities',
    title: 'Tasks',
  },
  {
    path: '/gallery',
    shortcutPath: '/projects/gallery',
    title: 'Gallery',
  },
];

export const rawMobileOnlyLinks: LayoutLinkInterface[] = [
  {
    path: '/account',
    title: 'Account Settings',
    testId: 'navbar-account-settings',
  },
  {
    path: 'https://explore.restoreforretail.com/restore-knowledge-center#subpage/home',
    title: 'Knowledge Hub',
    external: true,
    testId: 'navbar-knowledge-hub',
  },
  {
    path: '/content/feedback',
    title: 'Contact Support',
    testId: 'navbar-contact-support',
  },
  {
    path: '/content/licence_agreement',
    title: 'Licence Agreement',
    testId: 'navbar-licence-agreement',
  },
  {
    path: '/content/privacy',
    title: 'Privacy Policy',
    testId: 'navbar-privacy-policy',
  },
];

export const rawHeaderLinks = [
  {
    path: '/admin',
    title: 'Admin',
    testId: 'navbar-admin-tab',
  },
  {
    path: '/projects',
    title: 'My Projects',
    testId: 'navbar-my-projects-tab',
  },
];

const useGetPermittedLinks = (links: LayoutLinkInterface[]) => {
  const { hasPermission } = useAuthContext();

  return links.filter((link: LayoutLinkInterface) => {
    const routeLink: RouteInterface | undefined = privateRoutes.find(
      (route) =>
        route.path === link.path ||
        (!!link.shortcutPath && route.shortcutPath === link.shortcutPath)
    );

    return routeLink
      ? !routeLink.permission ||
          hasPermission(
            routeLink.permission.action,
            routeLink.permission.subject,
            routeLink.permission.conditions
          )
      : true;
  });
};

export const useAdminMainLinks = (): LayoutLinkInterface[] =>
  useGetPermittedLinks(rawAdminMainLinks);
export const useUserMainLinks = (): LayoutLinkInterface[] => useGetPermittedLinks(rawUserMainLinks);
export const useMobileOnlyLinks = (): LayoutLinkInterface[] =>
  useGetPermittedLinks(rawMobileOnlyLinks);
export const useHeaderLinks = (selectedLink?: string): LayoutLinkInterface[] => {
  const links = useGetPermittedLinks(rawHeaderLinks);
  return links.map((link) => {
    link.selected = !!selectedLink && link.title === selectedLink;
    return link;
  });
};
