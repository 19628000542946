import { ApolloError, useQuery } from '@apollo/client';
import { GET_TENANTS_FOR_EMAIL } from 'graphql/auth/queries/getTenantsForEmail';
import { useAuthContext } from 'context/auth.context';
import { LoginTenant } from '__generated__/graphql';

export default function useGetTenantsForCurrentUser(): {
  tenants: LoginTenant[];
  loading: boolean;
  error?: ApolloError;
} {
  const { user } = useAuthContext();

  const { data, error, loading } = useQuery(GET_TENANTS_FOR_EMAIL, {
    variables: { email: user?.user.email },
    skip: !user?.user.email,
  });

  return {
    tenants: data?.getTenantsForEmail ?? [],
    loading,
    error,
  };
}
