import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { SnackbarOrigin } from '@mui/material/Snackbar';
import { useSnackbarContext } from 'context/snackbar.context';
import { severityIcons } from 'components/AlertMessage/AlertMessage';
import { useClientContext } from 'context/client.context';
import { useTheme } from '@mui/material/styles';

export const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export interface State extends SnackbarOrigin {
  open: boolean;
}

const CustomSnackbar = (): JSX.Element => {
  const { showSnackbar, setShowSnackbar, alertSeverity, alertMessage, autoHideDuration } =
    useSnackbarContext();
  const { md, drawerWidth } = useClientContext();
  const [state] = React.useState<State>({
    open: showSnackbar,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;
  const IconComponent = alertSeverity && severityIcons[alertSeverity];
  const theme = useTheme();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
      sx={{
        width: md ? `calc(100vw - ${drawerWidth}rem - 1rem)` : '90vw',
        marginLeft: md ? `${drawerWidth / 2}rem` : undefined,
        maxWidth: 'xl',
      }}
    >
      {alertSeverity === 'critical' ? (
        <Alert
          onClose={handleClose}
          severity={'warning'}
          sx={{
            width: '100%',
            marginX: '1rem',
            backgroundColor: theme.colors.alertColors.critical.backgroundColor,
          }}
          icon={IconComponent && <IconComponent color='inherit' fontSize='inherit' />}
        >
          {alertMessage}
        </Alert>
      ) : (
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: '100%', marginX: '1rem' }}
          icon={IconComponent && <IconComponent color='inherit' fontSize='inherit' />}
        >
          {alertMessage}
        </Alert>
      )}
    </Snackbar>
  );
};

export default CustomSnackbar;
