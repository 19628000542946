import Layout from 'layout/Layout';
import React from 'react';
import styled from 'styled-components';

const MessageOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
`;

const MessageInner = styled.div`
  max-width: 37.188rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    margin: 0 2.75rem;
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 1.25rem;
  }
`;

const Body = styled.div`
  font-size: 1.125rem;
  font-weight: 500;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    font-size: 1rem;
  }
`;

export const NotAuthorizedBody = () => (
  <MessageOuter>
    <MessageInner>
      <Title>🔒Oops! You don&rsquo;t have permission to view this page.</Title>
      <Body>
        This page is for authorized users only. For assistance, contact your administrator or
        ReStore support at{' '}
        <a href='mailto:support@restoreforretail.com'>support@restoreforretail.com</a>.
      </Body>
    </MessageInner>
  </MessageOuter>
);

const NotAuthorizedPage = () => {
  return (
    <Layout title='Unauthorized'>
      <NotAuthorizedBody />
    </Layout>
  );
};

export default NotAuthorizedPage;
