import { gql } from '@apollo/client';

export const GET_TENANT = gql`
  query getTenant($id: Int!) {
    tenant(id: $id) {
      id
      name
      timezone
      dateFormat
      pillar1Name
      pillar2Name
      pillar3Name
      pillar4Name
      storeRef1Name
      storeRef2Name
      storeRef3Name
      storeStateName
      isSuperTenant
      emailAllowlist
      emailAllowlistEnabled
      userTagGroups {
        id
        name
        tags {
          id
          name
        }
      }
      storeTagGroups {
        id
        name
      }
      hiddenMediaCategoriesEnabled
    }
  }
`;
