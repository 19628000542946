import React, { useCallback, useMemo, useState } from 'react';
import { LoginChallengeResponse } from '__generated__/graphql';
import useAuthChallengeMutation from 'hooks/auth/mutations/useAuthChallengeMutation';
import { FormFieldInterface } from 'components/Form/Form.types';
import Form from 'components/Form/Form';
import validator from 'validator';
import { useAuthContext } from 'context/auth.context';

interface LoginChallengeProps {
  tenantId: number;
  email: string;
  challenge: LoginChallengeResponse;
  onError?: (error: string) => void;
  onNewChallenge: (newChallenge: LoginChallengeResponse) => void;
  onComplete?: () => void;
}

type Props = LoginChallengeProps;

const LoginChallenge = ({
  tenantId,
  email,
  challenge,
  onError,
  onNewChallenge,
  onComplete,
}: Props) => {
  const { login } = useAuthContext();
  const [authChallenge] = useAuthChallengeMutation();
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const fields: FormFieldInterface[] = useMemo(
    () => [
      {
        label: 'New Password',
        onChange: (value) => setNewPassword(value),
        type: 'password',
        value: newPassword,
        containedLabel: true,
      },
      {
        label: 'Confirm New Password',
        onChange: (value) => setConfirmPassword(value),
        type: 'password',
        value: confirmPassword,
        containedLabel: true,
      },
    ],
    [confirmPassword, newPassword]
  );

  const handleSubmit = useCallback(async () => {
    onError && onError('');
    const isStrongPassword = validator.isStrongPassword(newPassword, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    if (!isStrongPassword) {
      onError &&
        onError(
          'Password must be at least 8 characters with at least 1 uppercase, 1 number, and 1 symbol'
        );
      return false;
    }

    if (newPassword !== confirmPassword) {
      onError && onError('Passwords must match');
      return false;
    }

    const result = await authChallenge({
      variables: {
        input: {
          tenantId,
          userEmail: email,
          newPassword,
          session: challenge.session,
        },
      },
    });

    if (result.data?.authChallenge) {
      const response = result.data.authChallenge;
      if (response.success) {
        if (response.data) {
          // @ts-ignore
          login(response.data);
          onComplete && onComplete();
        } else if (response.challenge) {
          onNewChallenge(response.challenge);
        }
      } else {
        onError && onError(response.error || '');
      }
    } else {
      onError && onError('An unexpected error occurred');
    }
  }, [
    authChallenge,
    challenge.session,
    confirmPassword,
    email,
    login,
    newPassword,
    onError,
    onNewChallenge,
    tenantId,
  ]);

  return (
    <Form
      fields={fields}
      buttonText='Save & Login'
      onSubmit={handleSubmit}
      submitButtonProps={{
        fullWidth: true,
        color: 'primary',
        variant: 'contained',
      }}
      sx={{ p: 0 }}
    />
  );
};

export default LoginChallenge;
