import { styled } from '@mui/material/styles';
import { TextAlignTypes } from 'components/Elements/elements.types';

export const FormContainer = styled('div')<{ $textAlign?: TextAlignTypes }>`
  padding: 2rem;
  text-align: center;

  form {
    text-align: ${(props) => props.$textAlign ?? 'center'};
  }
`;
