export const entityNameRegex = new RegExp(/^[a-zA-Z0-9.'_\-+&!$()# ]+$/);
export const entityNameRegexLoose = new RegExp(/^[a-zA-Z0-9.'_\-+&!$()#/", ]+$/);

export const validateEntityName = (name?: string, loose = false): boolean => {
  if (!name?.length) return true; // allows input to be removed completely without triggering a false regex return
  return !!name.match(loose ? entityNameRegexLoose : entityNameRegex);
};

export const validateTimezone = (timezone?: string): boolean => {
  return timezone !== undefined && timezone?.length > 0;
};

export const entityNameMinimumLength = 2;

const emailRegex = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
);

export const validateEmail = (email: string) => {
  if (!email.length) return true; // allows input to be removed completely without triggering a false regex return
  return !!email.match(emailRegex);
};

const alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]*$/);

export const validateAlphaNumeric = (value: string) => {
  if (!value.length) return true; // allows input to be removed completely without triggering a false regex return
  return !!value.match(alphaNumericRegex);
};

const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{8,}$/);

export const validatePassowrd = (password: string) => {
  return !!password.match(passwordRegex);
};
