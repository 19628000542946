import { ApolloError, useQuery } from '@apollo/client';
import { Tenant } from '__generated__/graphql';
import { GET_TENANT } from 'graphql/tenants/queries/getTenant.gql';

export const useGetOneTenantQuery = ({
  id,
}: {
  id: number;
}): {
  tenant?: Tenant;
  loading: boolean;
  error?: ApolloError;
  refetch: () => void;
} => {
  const { data, error, loading, refetch } = useQuery(GET_TENANT, {
    skip: !id,
    variables: {
      id,
    },
  });

  return { tenant: data?.tenant, loading, error, refetch };
};
