import { themeOptions } from 'theme';
import styled from 'styled-components';

export const ErrorTextWrapper = styled.div`
  margin: 0 0 1rem 1rem;
  color: ${themeOptions.colors.error900};
  & img,
  svg {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;
