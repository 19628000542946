import { ReactNode } from 'react';
import styled from 'styled-components';

export type DisplayTypes = 'flex' | 'block' | 'inline-flex' | 'inline-block' | 'none';

export type AlignItemsTypes =
  | 'baseline'
  | 'center'
  | 'end'
  | 'first'
  | 'flex-end'
  | 'flex-start'
  | 'last'
  | 'normal'
  | 'safe'
  | 'self-end'
  | 'self-start'
  | 'start'
  | 'stretch'
  | 'unsafe'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

export type TextAlignTypes = 'left' | 'center' | 'right';

export type JustifyContentTypes =
  | 'space-between'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'left'
  | 'normal'
  | 'right'
  | 'safe'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'stretch'
  | 'unsafe'
  | 'inherit'
  | 'initial'
  | 'revert'
  | 'unset';

export type FlexDirectionTypes = 'row' | 'column' | 'row-reverse' | 'column-reverse';

export type OverflowTypes = 'inherit' | 'auto' | 'hidden' | 'scroll' | 'visible';

export type BorderStyleTypes =
  | 'dotted'
  | 'dashed'
  | 'solid'
  | 'double'
  | 'groove'
  | 'ridge'
  | 'inset'
  | 'outset'
  | 'none'
  | 'hidden';

export type PositionTypes = 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';

export type WhiteSpaceTypes =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces';

export type FontWeightTypes = 100 | 200 | 300 | 400 | 500 | 600;

export type ColSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type FlexWrapTypes =
  | 'nowrap'
  | 'wrap'
  | 'wrap-reverse'
  | 'inherit'
  | 'initial'
  | 'unset'
  | 'revert';

export interface ElementProps {
  display?: DisplayTypes;
  flexDirection?: FlexDirectionTypes;
  flexGrow?: string;
  flexWrap?: FlexWrapTypes;
  alignItems?: AlignItemsTypes;
  textAlign?: TextAlignTypes;
  justifyContent?: JustifyContentTypes;
  margin?: string; // CAREFUL not to overide left and right padding on rows
  padding?: string;
  children: ReactNode;
  width?: string;
  height?: string;
  bgColor?: string;
  color?: string;
  borderRadius?: string;
  maxWidth?: string;
  maxHeight?: string;
  minWidth?: string;
  minHeight?: string;
  overflowX?: OverflowTypes;
  overflowY?: OverflowTypes;
  position?: PositionTypes;
  whiteSpace?: WhiteSpaceTypes;
  fontWeight?: FontWeightTypes;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  gap?: string;
  onClick?: () => void;
}

export interface StyledElementProps {
  $display?: DisplayTypes;
  $alignItems?: AlignItemsTypes;
  $textAlign?: TextAlignTypes;
  $justifyContent?: JustifyContentTypes;
  $margin?: string;
  $padding?: string;
  $flexDirection?: FlexDirectionTypes;
  $flexGrow?: string;
  $flexWrap?: FlexWrapTypes;
  $width?: string;
  $height?: string;
  $bgColor?: string;
  $color?: string;
  $borderRadius?: string;
  $maxWidth?: string;
  $maxHeight?: string;
  $minWidth?: string;
  $minHeight?: string;
  $overflowX?: OverflowTypes;
  $overflowY?: OverflowTypes;
  $position?: PositionTypes;
  $whiteSpace?: WhiteSpaceTypes;
  $fontWeight?: FontWeightTypes;
  $top?: string;
  $right?: string;
  $bottom?: string;
  $left?: string;
  $gap?: string;
}

export const StyledElement = styled.div<StyledElementProps>`
  ${(props) => props.$display && `display: ${props.$display}`};
  ${(props) => props.$flexDirection && `flex-direction: ${props.$flexDirection}`};
  ${(props) => props.$textAlign && `text-align: ${props.$textAlign}`};
  ${(props) => props.$alignItems && `align-items: ${props.$alignItems}`};
  ${(props) => props.$justifyContent && `justify-content: ${props.$justifyContent}`};
  ${(props) => props.$padding && `padding: ${props.$padding}`};
  ${(props) => props.$margin && `margin: ${props.$margin}`};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.$height && `height: ${props.$height}`};
  ${(props) => props.$bgColor && `background-color: ${props.$bgColor}`};
  ${(props) => props.$color && `color: ${props.$color}`};
  ${(props) => props.$borderRadius && `border-radius: ${props.$borderRadius}`};
  ${(props) => props.$maxWidth && `max-width: ${props.$maxWidth}`};
  ${(props) => props.$maxHeight && `max-width: ${props.$maxHeight}`};
  ${(props) => props.$minWidth && `min-width: ${props.$minWidth}`};
  ${(props) => props.$minHeight && `min-width: ${props.$minHeight}`};
  ${(props) => props.$overflowX && `overflow-x: ${props.$overflowX}`};
  ${(props) => props.$overflowY && `overflow-y: ${props.$overflowY}`};
  ${(props) => props.$position && `position: ${props.$position}`};
  ${(props) => props.$whiteSpace && `white-space: ${props.$whiteSpace}`};
  ${(props) => props.$fontWeight && `font-weight: ${props.$fontWeight}`};
  ${(props) => props.$top && `top: ${props.$top}`};
  ${(props) => props.$right && `right: ${props.$right}`};
  ${(props) => props.$bottom && `bottom: ${props.$bottom}`};
  ${(props) => props.$left && `left: ${props.$left}`};
  ${(props) => props.$gap && `gap: ${props.$gap}`};
  ${(props) => props.$flexGrow && `flex-grow: ${props.$flexGrow}`};
  ${(props) => props.$flexWrap && `flex-wrap: ${props.$flexWrap}`};
`;
