import React, { useCallback, useState } from 'react';
import Modal from '../Modal/Modal';
import usePasswordReset from '../../hooks/auth/usePasswordReset';
import { FormFieldInterface } from '../Form/Form.types';
import { DialogContent, Typography } from '@mui/material';
import Form from '../Form/Form';
import { themeOptions } from '../../theme';
import { validateEmail } from '../../const/regex';

type Props = {
  tenantId: number;
  tenantName: string;
  email: string;
  open: boolean;
  onClose: () => void;
};
const ForgotPasswordModal = ({
  tenantId,
  tenantName,
  email: initialEmail,
  open,
  onClose,
}: Props) => {
  const [email, setEmail] = useState<string>(initialEmail ?? '');

  const { error, errorMessage, loading, noTenantFound, submitReset } = usePasswordReset(tenantId);
  const handleSubmit = useCallback(() => submitReset(email), [email, submitReset]);

  const fields: FormFieldInterface[] = [
    {
      label: 'Email',
      onChange: (email) => setEmail(email),
      type: 'email',
      value: email,
      containedLabel: true,
      realtimeValidator: validateEmail,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={'Forgot your password?'}
      disableDivider
      dialogProps={{ hideBackdrop: true, maxWidth: 'xs' }}
    >
      <DialogContent sx={{ pb: 6 }}>
        <Typography fontSize={18}>
          Enter your email below and we will send a message to reset your password for {tenantName}
        </Typography>
        <Form
          fields={fields}
          buttonText='Reset my password'
          onSubmit={handleSubmit}
          submitButtonProps={{
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
          }}
          sx={{ p: 0 }}
        />
        {noTenantFound && (
          <Typography color={themeOptions.colors.error900}>
            * Could not reset password for the account, please contact support or try again
          </Typography>
        )}
        {(errorMessage || error) && (
          <Typography color={themeOptions.colors.error900}>
            * {errorMessage ?? error?.message}
          </Typography>
        )}
      </DialogContent>
    </Modal>
  );
};

export default ForgotPasswordModal;
