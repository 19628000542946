import { gql } from '@apollo/client';

export const GET_REDIRECT = gql`
  query getRedirect($input: GetRedirectInput!) {
    getRedirect(getRedirectInput: $input)
  }
`;

export const COGNITO_LOGIN = gql`
  query cognitoLogin($input: CognitoLoginInput!) {
    cognitoLogin(cognitoLoginInput: $input) {
      data {
        caslAbilities
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          emailNotifications
          mobilePushNotifications
          timezone
          tenant {
            id
            name
            timezone
          }
          cognitoUsername
          isActive
          userGroups {
            id
            name
            cognitoGroupName
            displayColour
          }
          tags {
            id
            name
            displayColour
            archived
          }
          brands {
            id
            name
            archived
          }
        }
        tokens {
          id_token
          access_token
          refresh_token
          expires_at
          token_type
        }
      }
      challenge {
        challengeName
        session
        challengeParameters {
          requiredAttributes
          USER_ID_FOR_SRP
          userAttributes
        }
      }
    }
  }
`;

export const COGNITO_FORGOT_PASSWORD = gql`
  mutation forgotPassword($input: CognitoForgotPasswordInput!) {
    forgotPassword(cognitoForgotPasswordInput: $input) {
      attributeName
      deliveryMedium
      destination
    }
  }
`;

export const COGNITO_RESET_PASSWORD = gql`
  mutation resetPassword($input: CognitoResetPasswordInput!) {
    resetPassword(cognitoResetPasswordInput: $input) {
      success
      error
    }
  }
`;
