import React from 'react';
import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';

const StyledModal = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${({ theme }) => theme.colors.offWhite};
    border-radius: 0.75rem;
  }
`;

interface ModalProps {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  children?: React.ReactNode;
  dialogProps?: Partial<Omit<DialogProps, 'open' | 'onClose'>>;
  dialogTitleProps?: Partial<DialogTitleProps>;
  disableDivider?: boolean;
}

type Props = ModalProps;

const Modal = ({
  children,
  onClose,
  open,
  title,
  dialogProps,
  dialogTitleProps,
  disableDivider,
}: Props) => {
  return (
    <StyledModal
      scroll='paper'
      maxWidth='sm'
      fullWidth
      open={open ?? false}
      onClose={onClose}
      {...dialogProps}
    >
      {(title || onClose) && (
        <DialogTitle
          {...dialogTitleProps}
          sx={{ padding: '2rem', fontWeight: 400, fontSize: '1.5rem', ...dialogTitleProps?.sx }}
        >
          {title}
          {onClose && (
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 10,
                top: 10,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {title && !disableDivider && <Divider />}
      {children}
    </StyledModal>
  );
};

export default Modal;
