import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';

const SidebarContainer = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 5.625rem;

  padding-top: 6rem;

  z-index: ${({ theme }) => theme.zIndex.drawer + 4};

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: -2px 4px 14.5px -3px rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    padding-top: 0.75rem;

    height: 5rem;
    width: 100vw;
  }
`;

type SidebarProps = { buttons: ReactNode[] };
const Sidebar = ({ buttons }: SidebarProps) => {
  return (
    <SidebarContainer>
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        spacing={0}
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
      >
        {buttons}
      </Stack>
    </SidebarContainer>
  );
};

export default Sidebar;
