import { gql } from '__generated__/gql';

export const AUTH_CHALLENGE = gql(/* GraphQL */ `
  mutation AuthChallenge($input: AuthChallengeInput!) {
    authChallenge(input: $input) {
      data {
        caslAbilities
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          tenant {
            id
            name
          }
          cognitoUsername
          isActive
          userGroups {
            id
            name
            cognitoGroupName
            displayColour
          }
          tags {
            id
            ownerId
            name
            displayColour
            archived
          }
          brands {
            id
            name
            archived
          }
        }
        tokens {
          id_token
          access_token
          refresh_token
          expires_at
          token_type
        }
      }
      challenge {
        challengeName
        session
        challengeParameters {
          requiredAttributes
          USER_ID_FOR_SRP
          userAttributes
        }
      }
      success
      error
    }
  }
`);
