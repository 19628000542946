import { css, styled } from '@mui/material/styles';
import React, { ReactNode, useMemo } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { useClientContext } from 'context/client.context';
import Topnav from 'layout/TopNav/TopNav';
import Sidenav from './Sidenav/Sidenav';
import { HEADER_SIZE_DESKTOP, HEADER_SIZE_MOBILE } from 'theme';
import { Helmet } from 'react-helmet';
import NotFound from 'pages/NotFound';
import { useAuthContext } from 'context/auth.context';
import { useParams } from 'react-router-dom';
import { useGetCampaignByIdQuery } from 'hooks/campaigns/queries/useGetCampaignByIdQuery';
import Sidebar from '../components/Sidebar/Sidebar';
import { NotAuthorizedBody } from 'pages/NotAuthorized';

const ContentContainer = styled(Box)(
  ({ theme }) => css`
    padding-top: ${HEADER_SIZE_DESKTOP};
    background-color: ${(props) => props.theme.palette.background.default};

    ${theme.breakpoints.down('sm')} {
      padding-top: ${HEADER_SIZE_MOBILE};
    }
  `
);

export type SideNavGroupType = 'admin' | 'user';

interface LayoutProps {
  children: ReactNode;
  sideNavGroup?: SideNavGroupType;
  title: string;
  selectedTab?: string;
  ignoreContentContainerWidth?: boolean;
  sidebarButtons?: ReactNode[];
}

const Layout = ({
  children,
  sideNavGroup,
  title,
  selectedTab,
  ignoreContentContainerWidth,
  sidebarButtons,
}: LayoutProps): JSX.Element => {
  const { sm, md, drawerWidth } = useClientContext();
  const { userTenantName } = useAuthContext();
  const { projectId, campaignId } = useParams();
  const { campaign, loading, error } = useGetCampaignByIdQuery(projectId ?? campaignId);
  const notAuthorized = error?.message === 'Unauthorized';

  const hasSidebar = useMemo(() => sidebarButtons && sidebarButtons.length > 0, [sidebarButtons]);

  const contentWidth = useMemo(() => {
    const removeSidenav = !!sideNavGroup && md && !ignoreContentContainerWidth;
    return `calc(100% - ${removeSidenav ? '15rem' : '0px'} - ${
      hasSidebar && sm ? '5.625rem' : '0px'
    })`;
  }, [hasSidebar, ignoreContentContainerWidth, sm, md, sideNavGroup]);

  if ((projectId || campaignId) && !campaign && !loading && !notAuthorized) {
    return <NotFound />;
  }

  const pageTitle = `${title}${
    userTenantName ? ` - ${userTenantName}` : ''
  } | ReStore for Retail v2`;

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CssBaseline />
      <Topnav selectedTab={selectedTab} />
      {!notAuthorized && <Sidenav sideNavGroup={sideNavGroup} />}
      <ContentContainer
        id='contentContainer'
        sx={{
          marginLeft: !notAuthorized && !!sideNavGroup && md ? `${drawerWidth}rem` : '0',
          marginRight: !notAuthorized && hasSidebar && md ? '5.625rem' : '0',
          marginBottom: { xs: hasSidebar ? '5rem' : 0, sm: 0 },
          width: contentWidth,
        }}
      >
        {error?.message === 'Unauthorized' ? <NotAuthorizedBody /> : children}
      </ContentContainer>
      {hasSidebar && <Sidebar buttons={sidebarButtons ?? []} />}
    </Box>
  );
};

export default Layout;
