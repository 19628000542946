import React, { useMemo, useState } from 'react';
import { useAuthContext } from 'context/auth.context';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tenant } from '__generated__/graphql';
import { useGetTenantsQuery } from 'hooks/tenants/queries/useGetTenantsQuery';
import { useClientContext } from 'context/client.context';

const TenantHeader = styled(Typography)`
  font-weight: 500;
  padding-left: 1rem;
  padding-right: 0.5rem;
  font-size: 1.25rem;
`;

const SwitchTenant = styled(Button)`
  margin-left: 1rem;
  padding-top: 0;
  font-size: 0.775rem;
  font-weight: 500;
  border: none;
  color: ${({ theme }) => theme.colors.brandBlue};
  :hover {
    background: none;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brandBlue};
  }
`;

const ModalBody = styled(DialogContent)`
  height: 70vh;
  padding: 2rem;
`;

const StyledModal = styled(Dialog)`
  .MuiDialog-paper {
    background-color: ${({ theme }) => theme.colors.offWhite};
    border-radius: 0.75rem;
  }
`;

const tenantSorter = (a: Tenant, b: Tenant) => {
  if (a.name < b.name) {
    return -1;
  } else if (b.name > a.name) {
    return 1;
  } else {
    return 0;
  }
};

export const TenantSelector = ({ open, visible }: { open?: boolean; visible?: boolean }) => {
  const { tenants } = useGetTenantsQuery({});
  const { md } = useClientContext();
  const { userTenantId, setUserTenant, user, userTenantOnLogin } = useAuthContext();
  const [show, setShow] = useState(open ?? false);
  const selectedTenant = tenants.find((tenant) => tenant.id === userTenantId);
  const userTenant = user && tenants.find((tenant) => tenant.id === userTenantOnLogin?.id);
  const isSuperTenantUser = userTenant?.isSuperTenant;

  const sortedTenants = useMemo(
    () => (tenants ? tenants.slice().sort(tenantSorter) : []),
    [tenants]
  );

  return (
    <>
      {visible && (
        <Box sx={{ paddingTop: '1.5rem', ...(!!md && { paddingBottom: '2rem' }) }}>
          <TenantHeader variant='h3' noWrap>
            {selectedTenant?.name ?? 'No Tenant Selected'}
          </TenantHeader>
          {isSuperTenantUser && (
            <SwitchTenant
              data-testid='sidebar-change-tenant'
              variant='text'
              size='small'
              onClick={() => setShow(true)}
            >
              Change Tenant
            </SwitchTenant>
          )}
          <Divider sx={{ marginTop: isSuperTenantUser ? '1rem' : '2rem' }} />
        </Box>
      )}
      <StyledModal
        scroll='paper'
        maxWidth='sm'
        fullWidth
        open={show}
        onClose={() => setShow(false)}
      >
        <DialogTitle sx={{ padding: '2rem', fontWeight: 400, fontSize: '1.5rem' }}>
          Select Desired Tenant
          <IconButton
            aria-label='close'
            onClick={() => setShow(false)}
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <ModalBody>
          <List sx={{ pt: 0 }}>
            {sortedTenants.map((tenant) => (
              <ListItem key={tenant.id} disableGutters>
                <ListItemButton
                  selected={tenant.id === selectedTenant?.id}
                  onClick={() => {
                    setUserTenant(tenant);
                    setShow(false);
                  }}
                  sx={{ borderRadius: '4px' }}
                >
                  <ListItemText primary={tenant.name} sx={{ fontWeight: 500 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </ModalBody>
      </StyledModal>
    </>
  );
};

export default TenantSelector;
