import { useCallback, useState } from 'react';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { GET_ONE_TENANT_FOR_EMAIL } from 'graphql/auth/queries/getOneTenantForEmail';
import { COGNITO_FORGOT_PASSWORD } from 'graphql/auth/queries/authLogin';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth.context';

type ReturnType = {
  noTenantFound: boolean;
  errorMessage?: string;
  error?: ApolloError;
  loading: boolean;
  submitReset: (email: string) => Promise<void>;
};

export default function usePasswordReset(initialTenant?: string | number): ReturnType {
  const [noTenantFound, setNoTenantFound] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [getOneTenantForEmail] = useLazyQuery(GET_ONE_TENANT_FOR_EMAIL);
  const [forgotPassword, { loading, error }] = useMutation(COGNITO_FORGOT_PASSWORD);
  const navigate = useNavigate();

  const { logout } = useAuthContext();

  const handleSubmit = useCallback(
    async (email: string) => {
      setNoTenantFound(false);
      setErrorMessage('');

      let tenantId = initialTenant ? Number(initialTenant) : undefined;
      if (!tenantId) {
        const result = await getOneTenantForEmail({
          variables: { email },
        }).catch(() => setNoTenantFound(true));

        tenantId = result?.data?.getOneTenantForEmail.id;
      }

      if (tenantId) {
        forgotPassword({
          variables: {
            input: {
              tenantId,
              userEmail: email,
            },
          },
        }).then(
          (forgotPasswordResult) => {
            //we need to logout and then save the tenant ID here, so that if the user is resetting their password
            //through the tenancy switcher they don't get stuck in a loop--
            //  - log in to account a
            //  - tenancy switch to account b, reset your password
            //  - password for b is reset successfully & user redirected to login
            //  - login form selects account a again, user can't log in with newly-reset password
            logout(false);
            localStorage.setItem('restore.user.lastTenantId', tenantId?.toString() ?? '');
            if (forgotPasswordResult.data.forgotPassword.attributeName === 'Temporary Password') {
              navigate(`/temporaryPassword/${encodeURIComponent(email)}/${tenantId}`);
            } else {
              navigate(`/resetPassword/${encodeURIComponent(email)}/${tenantId}`);
            }
          },
          (rejection) => {
            setErrorMessage(rejection?.errorMessage);
          }
        );
      } else {
        setNoTenantFound(true);
        return;
      }
    },
    [forgotPassword, getOneTenantForEmail, logout, navigate, initialTenant]
  );

  return {
    loading,
    noTenantFound,
    error,
    errorMessage,
    submitReset: handleSubmit,
  };
}
