import React from 'react';

const CheckedBox = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='16' height='16' fill='#F5F5F5' />
    <g id='Iconography' clipPath='url(#clip0_288_70)'>
      <rect width='1356' height='1724' transform='translate(-376 -611)' fill='white' />
      <g id='checkbox'>
        <g id='Property 1=selected'>
          <path
            id='Vector'
            d='M6.64444 11.5333L13.0667 5.08889L12.1111 4.13333L6.64444 9.62222L4 6.97778L3.04444 7.93333L6.64444 11.5333ZM1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333Z'
            fill='black'
          />
        </g>
        <rect
          x='-48.5'
          y='-19.5'
          width='80'
          height='52'
          rx='4.5'
          stroke='#9747FF'
          strokeDasharray='10 5'
        />
      </g>
    </g>
  </svg>
);

export default CheckedBox;
