import { gql } from '@apollo/client';

export const GET_ONE_TENANT_FOR_EMAIL = gql`
  query getOneTenantForEmail($email: String!) {
    getOneTenantForEmail(email: $email) {
      id
      name
    }
  }
`;
