import { gql } from '@apollo/client';

export const GET_TENANTS = gql`
  query getTenants($tenantsInput: TenantsInput!) {
    tenants(tenantsInput: $tenantsInput) {
      id
      name
      isSuperTenant
      timezone
    }
  }
`;
