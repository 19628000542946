import React, { ReactNode } from 'react';
import { CaslPermission } from 'config/ability';
import { useAuthContext } from 'context/auth.context';

interface PermissionCheckProps {
  children: ReactNode;
  permission?: CaslPermission;
}

const PermissionCheck = ({ children, permission }: PermissionCheckProps): JSX.Element => {
  const { loading, hasPermission } = useAuthContext();
  const permissible =
    !permission || hasPermission(permission.action, permission.subject, permission?.conditions);

  if (!loading && permissible) {
    return <>{children}</>;
  }

  return <></>;
};

export default PermissionCheck;
