import React from 'react';

const UncheckedBox = ({
  disabled,
  fillColor,
}: {
  disabled?: boolean | undefined;
  fillColor?: string;
}) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {fillColor && <rect x='0.5' y='0.5' width='15' height='15' fill={fillColor} stroke='none' />}
    <path
      d='M1.33333 16C0.977778 16 0.666667 15.8667 0.4 15.6C0.133333 15.3333 0 15.0222 0 14.6667V1.33333C0 0.977778 0.133333 0.666667 0.4 0.4C0.666667 0.133333 0.977778 0 1.33333 0H14.6667C15.0222 0 15.3333 0.133333 15.6 0.4C15.8667 0.666667 16 0.977778 16 1.33333V14.6667C16 15.0222 15.8667 15.3333 15.6 15.6C15.3333 15.8667 15.0222 16 14.6667 16H1.33333ZM1.33333 14.6667H14.6667V1.33333H1.33333V14.6667Z'
      fill={disabled ? '#BDBDBD' : 'black'}
    />
  </svg>
);

export default UncheckedBox;
